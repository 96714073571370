import { SelectChangeEvent } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dynamic from 'next/dynamic'

const FormControl = dynamic(() => import('@mui/material/FormControl'), {})
const MenuItem = dynamic(() => import('@mui/material/MenuItem'), {})
const Select = dynamic(() => import('@mui/material/Select'), {})

export type Props = {
  /**
   * The field name
   */
  name: string
  /**
   * The field id
   */
  id: string
  /**
   * The field label
   */
  label?: React.ReactNode

  /**
   * The value binded to field
   */
  value: string

  /**
   * Options to be rendered in the select menu
   */
  options: { label: string; value: string; disabled?: boolean }[]

  onChange: (e: SelectChangeEvent) => void
  onFocus?: () => void
  onBlur?: () => void
  readOnly?: boolean
  /**
   * Apply success class
   */
  success?: boolean

  /**
   * Apply error class
   */
  error?: boolean

  /**
   * Default disabled option to be displayed at the top of the menu list.
   * @defaultValue Select...
   */
  defaultOption?: string
}

const MuiSelect = (props: Props) => {
  return (
    <div data-testid="mui-select-main" className={` ${props.success ? 'success' : props.error ? 'error' : ''}`}>
      {props.label && (
        <label htmlFor={props.id} className="mb-2.5 block font-bold text-gray-900 dark:text-white">
          {props.label}
        </label>
      )}
      <div className="icon-input">
        <FormControl className="mui-select w-full">
          <Select
            data-testid="select-dropdown"
            id={props.id}
            name={props.name}
            aria-label={props.name}
            inputProps={{ 'aria-label': props.name }}
            className="block rounded-lg border border-gray-300 bg-gray-50 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:placeholder-opacity-75 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            value={props.value}
            //@ts-ignore
            onChange={props.onChange}
            displayEmpty
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            readOnly={props.readOnly}
            IconComponent={ExpandMoreIcon}
          >
            <MenuItem sx={{ fontSize: '18px' }} value="" disabled>
              {props.defaultOption || 'Select...'}
            </MenuItem>
            {props.options.map(option => (
              <MenuItem value={option.value} sx={{ fontSize: '18px' }} key={option.value} disabled={option.disabled}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <span className="form-icon"></span>
        </FormControl>
      </div>
    </div>
  )
}

export default MuiSelect
