import { DBData } from '@qtc-repo/common/constants'

export const upsertDetails = async (details: Record<string, any>): Promise<{ data: DBData | null; error: any }> => {
  if (!(details.email || details.link_code)) {
    return { data: null, error: new Error('At least one unique column required') }
  }
  return (
    await fetch('/api/upsert-cust', {
      method: 'POST',
      body: JSON.stringify(details),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).json()
}
