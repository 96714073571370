import BaseModal from '@qtc-repo/ui/components/Modal/BaseModal'

type Props = {
  show: boolean
  onClose: () => void
}

const TaxDeductionModal = (props: Props) => {
  return (
    <BaseModal
      title={'Tax deductions'}
      body={
        <>
          Please be aware that the tax deduction applied to your compensation payment pertains exclusively to the
          statutory interest component and not the entire sum. Therefore, a standardised 20% calculation from the
          overall amount is not applicable in this context.
          <br />
          <br />
          The estimated figure has been derived from our internal analysis of lender calculations. Its sole purpose is
          to serve as a helpful reference assisting you in accurately determining the correct figure within your offer
          letter.
          <br />
          <br />
          To obtain specific information about the tax deductions associated with your compensation, you must consult
          the Offer Letter you received from your bank or lender during the claim process. This document includes a
          section outlining the awarded amounts and provides specific details about tax deductions. If you no longer
          have access to your ‘Offer Letter’, you must contact your bank or lender directly to request this information.
        </>
      }
      {...props}
    />
  )
}

export default TaxDeductionModal
