import { useEffect, useState } from 'react'
import { useSystemValues } from '../contexts/SharedContext'
import { validateEmail } from '../utils'
import { DATA8_TOKEN } from '../constants'

enum RESULT {
  VALID = 'Valid',
  INVALID = 'Invalid',
  INCONCLUSIVE = 'Inconclusive',
  CATCHALL = 'CatchAll',
  GREYLISTED = 'GreyListed',
}
// Boolean indicating if to use the data8 api for validation or just basic regex
const ENABLE_DATA8_VALIDATION = false
const updateLocalStorage = (value: Record<string, boolean | string>) => {
  localStorage.setItem('email_validation', JSON.stringify(value))
}
export const useEmailValidator = () => {
  const { setEmailValidation, emailValidation } = useSystemValues()
  const [lastResult, setLastResult] = useState<{
    email: string
    isValid: boolean
  } | null>(null)

  // validate email in localstorage on first load
  useEffect(() => {
    const storage_data = localStorage.getItem('email_validation')
    if (storage_data) {
      const data = JSON.parse(storage_data) as typeof emailValidation & { email: string }
      ValidateEmail(data.email, true)
    }
  }, [])

  const ValidateEmail = async (email: string, init?: boolean): Promise<boolean> => {
    if (!email) return Promise.resolve(false)
    if (!ENABLE_DATA8_VALIDATION) {
      const isValid = validateEmail(email)
      setEmailValidation(prevState => ({ ...prevState, isValid }))
      setLastResult({ email, isValid: isValid })
      return Promise.resolve(isValid)
    }
    return new Promise<boolean>(resolve => {
      const storage_data = localStorage.getItem('email_validation')
      if (!init && storage_data) {
        const data = JSON.parse(storage_data) as typeof emailValidation & { email: string }
        if (data.email === email) {
          setEmailValidation(prevState => ({ ...prevState, isValid: data.isValid }))
          setLastResult({ email, isValid: data.isValid })
          resolve(data.isValid)
          return
        }
      }
      setEmailValidation(prev => ({ ...prev, loading: true }))

      if (lastResult?.email === email) {
        setEmailValidation(prev => ({ ...prev, loading: false }))
        resolve(lastResult.isValid)
        return
      }

      if (!validateEmail(email)) {
        setEmailValidation(prev => {
          const val = { ...prev, loading: false, isValid: false }
          updateLocalStorage({ ...val, email })
          return val
        })
        setLastResult({ email, isValid: false })
        resolve(false)
        return
      }

      fetch('https://webservices.data-8.co.uk/EmailValidation/IsValid.json', {
        method: 'POST',
        headers: {
          Authorization: DATA8_TOKEN,
        },
        body: JSON.stringify({ email, level: 'Address' }),
      })
        .then(res => res.json())
        .then(data => {
          const isValid = data.Result === RESULT.VALID
          setLastResult({ email, isValid })
          setEmailValidation(prev => {
            const val = { ...prev, isValid }
            updateLocalStorage({ ...val, email })
            return val
          })
          resolve(isValid)
        })
        .catch(() => {
          resolve(false)
        })
        .finally(() => {
          setEmailValidation(prev => ({ ...prev, loading: false }))
          updateLocalStorage({ ...emailValidation, loading: false, email })
          resolve(emailValidation.isValid)
        })
    })
  }
  return {
    ValidateEmail,
  }
}
