import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useSystemValues } from '@/contexts/ValueContext'
import { font, STEP } from '@qtc-repo/common/constants'
import { createTheme, ThemeProvider } from '@mui/material'
import { useThemeContext } from '@qtc-repo/common/contexts/ThemeContext'
import Main from '@qtc-repo/ui/components/Layout/Main'
import { useEffect } from 'react'

const muiTheme = createTheme({
  typography: {
    fontFamily: font.style.fontFamily,
  },
})
const Header = dynamic(() => import('@qtc-repo/ui/components/Layout/Header/Header'), {})
const SmallFooter = dynamic(() => import('@qtc-repo/ui/components/Layout/Footer/SmallFooter'), {})
const Footer = dynamic(() => import('@qtc-repo/ui/components/Layout/Footer/Footer'), {})

const { publicRuntimeConfig } = getConfig()

type LayoutProps = {
  title?: string
  description?: string
  date?: string
  socialPreview?: string
  children: React.ReactNode
}

const Layout = ({ children, ...customMeta }: LayoutProps) => {
  const router = useRouter()
  const { asPath } = router
  const { name, url, title, description, socialPreview } = publicRuntimeConfig.site

  const meta = {
    name,
    url,
    title,
    description,
    socialPreview,
    ...customMeta,
  }
  const { theme } = useThemeContext()

  const { step } = useSystemValues()

  useEffect(() => {
    document.documentElement.style.fontFamily = font.style.fontFamily
  }, [])

  return (
    <div className={`flex min-h-screen w-full flex-col justify-start`}>
      <style jsx>{`
        * {
          font-family: ${font.style.fontFamily};
        }
      `}</style>
      <Head>
        <link rel="icon" href="/images/favicon120.png" key="favicon" />
        <link rel="canonical" href={`${url}${asPath}`} key="canonical" />
        <link rel="apple-touch-icon" sizes="120x120" href="/images/favicon120.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/images/favicon120.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" key="twitter_card" />
        <meta name="twitter:title" content={meta.title} key="twitter_title" />
        <meta name="twitter:description" content={meta.description} key="twitter_description" />
        <meta name="twitter:image" content={`${url}${socialPreview}`} key="twitter_image" />
        {/* Open Graph */}
        <meta property="og:url" content={`${url}${asPath}`} key="og_url" />
        <meta property="og:site_name" content={meta.name} key="og_site_name" />
        <meta property="og:title" content={meta.title} key="og_title" />
        <meta property="og:description" content={meta.description} key="og_description" />
        <meta property="og:image" content={`${url}${socialPreview}`} key="og_image" />
        <meta property="og:image:width" content={`1200`} key="og_image_width" />
        <meta property="og:image:height" content={`630`} key="og_image_height" />
        <meta name="description" content={meta.description} key="description" />
        {meta.date && <meta property="article:published_time" content={meta.date} />}
        <title key="title">{meta.title}</title>
      </Head>
      <ThemeProvider theme={muiTheme}>
        {step === STEP.INIT && (
          <>
            {/* <Banner /> */}
            <Header theme={theme} />
          </>
        )}
        <Main>{children}</Main>
        {step === STEP.INIT ? <Footer theme={theme} /> : <SmallFooter />}
      </ThemeProvider>
    </div>
  )
}

export default Layout
