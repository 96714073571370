import { ReactNode, useEffect } from 'react'
import ModalPopover from '../ModalPopover'
import { disableScroll, enableScroll } from '@qtc-repo/common/utils'
import { Button } from '../Button'

type Props = {
  /**
   * Show or hide the modal
   */
  show: boolean
  /**
   * Function to be called when the modal close button is clicked
   */
  onClose: () => void
  /**
   * Title / header of the modal
   */
  title: string
  /**
   * Body content of the modal
   */
  body: ReactNode
  /**
   * size of the modal
   * @defaultValue default
   */
  size?: 'sm' | 'default' | 'lg' | 'xl'
}

const BaseModal = ({ size = 'default', ...props }: Props) => {
  useEffect(() => {
    if (props.show) {
      if (document.body.classList.value?.includes('sb-main')) return // dont disable scroll in storybook
      disableScroll()
    }
  }, [props.show])

  const sizes = {
    sm: 'max-w-md',
    default: 'max-w-2xl',
    lg: 'max-w-4xl',
    xl: 'max-w-7xl',
  }
  return (
    <ModalPopover
      show={props.show}
      content={
        <div
          tabIndex={-1}
          className={`${
            props.show ? 'flex' : 'hidden'
          } fixed left-0 right-0 top-0 z-50 h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden scroll-smooth md:inset-0`}
        >
          <div className="fixed inset-0 left-0 top-0 h-screen w-screen bg-gray-900/50 backdrop-blur-sm" />
          <div className={`relative w-full ${sizes[size]} max-h-full p-4`}>
            <div className="relative w-full rounded-lg bg-white shadow dark:bg-gray-800">
              <div className="sticky left-0 top-0 z-10 flex items-center justify-between rounded-t border-b bg-white p-4 dark:border-gray-600 dark:bg-gray-800 md:p-5">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{props.title}</h3>
                <button
                  type="button"
                  className="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-900 hover:bg-gray-200 hover:text-gray-900 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="default-modal"
                  onClick={() => {
                    enableScroll()
                    props.onClose()
                  }}
                >
                  <svg
                    className="h-3 w-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="space-y-4 p-4 md:p-5">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">{props.body}</p>
              </div>
              <div className="flex items-center rounded-b border-t border-gray-200 p-4 dark:border-gray-600 md:p-5">
                <Button
                  onClick={() => {
                    enableScroll()
                    props.onClose()
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default BaseModal
