import { ReactNode, useEffect, useRef } from 'react'

type Props = {
  content: ReactNode
  show: boolean
}
const ReusablePopover = ({ content, show }: Props) => {
  const popoverRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!popoverRef.current) return
    if (!show) {
      popoverRef.current.removeAttribute('popover-open')
    } else {
      popoverRef.current.setAttribute('popover-open', '')
    }
  }, [show, popoverRef.current])
  if (!show) return null
  return (
    <>
      <div ref={popoverRef} data-popover={'true'} className="min-h-screen">
        {content}
      </div>
      <style jsx>{`
        [popover] {
          display: none;
        }

        [popover-open] {
          display: block;
        }
      `}</style>
    </>
  )
}

export default ReusablePopover
