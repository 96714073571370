type Props = {
  text: string
  onClick?: VoidFunction
  href?: string
  hover?: boolean
}
const LinkText = (props: Props) => {
  return (
    <a
      href={props.href || '#'}
      target={'__blank'}
      className={
        props.hover
          ? 'hover:text-blue-600 hover:underline dark:hover:text-blue-500'
          : `text-blue-600 underline dark:text-blue-500`
      }
      onClick={e => {
        if (!props.href) e.preventDefault()
        props.onClick?.()
      }}
    >
      {props.text}
    </a>
  )
}

export default LinkText
