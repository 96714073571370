import { useEffect, useState } from 'react'
import { DBData, LendersData, PDFTYPE, REFUNDS, STEP, UserData } from '@qtc-repo/common/constants'
import { useSystemValues } from '@/contexts/ValueContext'
import { GetServerSidePropsContext } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Layout from '@/components/Layout'
import { TAX_YEARS } from '@/components/steps/Refunds'
import { parse } from 'postcode'
import { disableScroll, enableScroll, getBase64Image } from '@qtc-repo/common/utils'
import { isMobile, isTablet, mobileModel, mobileVendor } from 'react-device-detect'

import { createClient as createServerClient } from '@qtc-repo/common/supabase/server-props'
import { upsertDetails } from '../lib/utils'
import { useEmailValidator } from '@qtc-repo/common/hooks/useEmailValidator'

const deviceType = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'

const questions = [
  {
    title: 'Why can I claim a tax refund on my PPI payout?',
    body: 'You can claim a tax refund on your PPI payout because the interest paid on the compensation by the lender is subject to income tax. As a result, you are entitled to a tax refund on the interest paid, which is calculated based on the statutory interest rate set by HMRC.',
  },
  {
    title: 'How do I claim my tax refund on my PPI payout?',
    body: 'To claim your tax refund on your PPI payout, you can complete our online form. Our team of experts will handle the entire process, from gathering the necessary information to submitting your claim to HMRC.',
  },
  {
    title: 'Who is eligible to claim a tax refund on their PPI payout?',
    body: 'If you have received a PPI payout within the last five years, you may be eligible to claim a tax refund on the interest paid. However, the specific eligibility criteria can vary based on your individual circumstances. To find out if you are eligible, you can complete our online form or consult with a tax professional.',
  },
  {
    title: 'How much money could I be entitled to?',
    body: 'The amount you could be owed varies based on a number of factors, including the size of your PPI payout and the amount of statutory interest paid. In some cases, customers have been able to claim back thousands of pounds.',
  },
  {
    title: 'Is the process of claiming a tax refund complicated?',
    body: 'Not at all! We make the process as simple and hassle-free as possible. All you need to do is complete our online form, and our team of experts will handle the rest.',
  },
  {
    title: 'How long does the process of claiming a tax refund take?',
    body: 'The amount of time it takes to receive your tax refund can vary, but in most cases, you should expect to receive your refund within a few weeks of submitting your claim to HMRC.',
  },
]
const Spinner = dynamic(() => import('@/components/BackdropSpinner'))
const Claim = dynamic(() => import('@/components/Claim/Claim'), {
  loading: () => (
    <div className="flex h-screen w-screen items-center justify-center bg-white text-gray-600 dark:bg-gray-800 dark:text-white ">
      <Spinner />
    </div>
  ),
})
const Faq = dynamic(() => import('@qtc-repo/ui/components/Faq/Faq'), {
  loading: () => (
    <div className="flex h-screen w-screen items-center justify-center bg-white text-gray-600 dark:bg-gray-800 dark:text-white ">
      <Spinner />
    </div>
  ),
})
const Loading = dynamic(() => import('@/components/Loading'), {
  loading: () => (
    <div className="flex h-screen w-screen items-center justify-center bg-white text-gray-600 dark:bg-gray-800 dark:text-white  ">
      <Spinner />
    </div>
  ),
})
const HeroSection = dynamic(() => import('@/components/HeroSection'), {
  loading: () => (
    <div className="flex h-screen w-screen items-center justify-center bg-white text-gray-600 dark:bg-gray-800 dark:text-white ">
      <Spinner />
    </div>
  ),
})
const TermsModal = dynamic(() => import('@/components/Modals/TermsModal'), {})
const PrivacyModal = dynamic(() => import('@/components/Modals/PrivacyModal'), {})
const R40Modal = dynamic(() => import('@/components/Modals/R40Modal'), {})
const AuthorizeAgentModal = dynamic(() => import('@/components/Modals/648Modal'), {})
const Toaster = dynamic(() => import('sonner').then(mod => mod.Toaster))

type HomeProps = {
  link_code: string
  data: DBData
}
export default function Home(props: HomeProps) {
  const [init, setInit] = useState(true)
  const {
    setLinkCode,
    setDbData,
    setUserIp,
    setUserEmail,
    setUserPhone,
    showLoadingPage,
    setUserData,
    setFirstEvents,
    firstEvents,
    setTaxYears,
    setDevice,
    setSelectedAddress,
    step,
    setStep,
    getNextStep,
    fileURL,
    setLendersData,
    setRefunds,
    setPayoutDone,
    setDetailsChecked,
    stepValid,
    setStepValid,
    setSummaryConfirmed,
    emailValidation,
    userData,
    setShowBackdrop,
  } = useSystemValues()
  const { ValidateEmail } = useEmailValidator()

  const router = useRouter()
  const getPrevData = async (data: DBData) => {
    if (!data) {
      return
    }
    let dob = { day: '', month: '', year: '' }
    if (data?.birthdate_str) {
      const separator = data.birthdate_str.includes('/') ? '/' : data.birthdate_str.includes('-') ? '-' : ' '
      const [day, month, year] = data.birthdate_str.split(separator)
      dob = { day, month, year }
    }
    let signatureData = ''
    if (data?.signatureUrl) {
      signatureData = await getBase64Image(data.signatureUrl)
    }

    if (data?.refunds_breakdown) {
      const selectedLenders = Object.keys(data.refunds_breakdown)
      setLendersData({
        firstEvent: false,
        selectedLenders,
      })
      setRefunds(data.refunds_breakdown)
    }
    /* update the form data with existing user data */

    const user_data = {
      firstName: data?.firstName ? data.firstName : '',
      lastName: data?.lastName ? data.lastName : '',
      email: data?.email ? data.email : '',
      postCode: data?.postCode ? (parse(data.postCode).postcode as string) : '',
      address: data?.address ? data.address : '',
      day: dob.day,
      month: dob.month,
      year: dob.year,
      phone: data?.phone || '',
      signatureData,
      earnings: data?.earnings || '',
      employment_status: data?.employment_status || '',
      insurance: data?.insurance || '',
    }
    setUserData(user_data)
    if (user_data.email) {
      await ValidateEmail(user_data.email)
    }

    if (user_data.address) {
      setSelectedAddress(user_data.address)
    }
    setFirstEvents(
      (Object.keys(firstEvents) as (keyof typeof firstEvents)[]).reduce(
        (obj, ev) => {
          obj[ev] = false
          return obj
        },
        {} as typeof firstEvents
      )
    )
    setTaxYears({
      tax_years: Object.keys(TAX_YEARS).reduce(
        (obj, key) => {
          obj[key] = data?.tax_years?.[key]
          return obj
        },
        {} as Record<string, string>
      ),
      // if a key in tax_years has been previously filled, set its firstEvent to false and vice-versa

      firstEvents: Object.keys(TAX_YEARS).reduce(
        (obj, key) => {
          obj[key] = !data?.tax_years?.[key]
          return obj
        },
        {} as Record<string, boolean>
      ),
    })
  }

  useEffect(() => {
    ;(async () => {
      // disable auto brand switching
      // const minutes = new Date().getMinutes()
      // const brand = Math.floor(minutes / 10) % 2
      // setBrand(brand)
      if (!props.link_code) {
        const usr_dat = localStorage.getItem('usr_data')
        if (usr_dat) {
          const data = JSON.parse(usr_dat) as UserData
          setUserData(data)
          for (const item in data) {
            if (data[item as keyof UserData]) {
              firstEvents[item as keyof UserData] = false
            }
          }
          if (data.email) {
            void ValidateEmail(data.email)
          }
          setFirstEvents({ ...firstEvents })
        }
        const step_valid = localStorage.getItem('step_valid')
        if (step_valid) {
          const data = JSON.parse(step_valid) as Record<STEP, boolean>
          setStepValid(data)
        }
        const lendersData = localStorage.getItem('lenders_data')
        if (lendersData) {
          const data = JSON.parse(lendersData) as LendersData
          setLendersData({ ...data, firstEvent: false })
        }
        const refundsData = localStorage.getItem('refunds_data')
        if (refundsData) {
          const data = JSON.parse(refundsData) as REFUNDS
          setRefunds({ ...data })
        }
        const payoutDone = localStorage.getItem('payout_done')
        if (payoutDone) {
          const data = JSON.parse(payoutDone) as Record<string, boolean>
          setPayoutDone({ ...data })
        }
        const checked = localStorage.getItem('details_checked')
        setDetailsChecked(checked === 'true')
        const confirmed = localStorage.getItem('summary_confirmed')
        setSummaryConfirmed(confirmed === 'true')
      }
      if (router.query.c && !props.link_code) {
        void router.push('/')
      }
      setLinkCode(props.link_code)
      const data = props.data
      if (data) {
        setDbData(_ => ({ ...data }))
        setUserEmail(data.email)
        setUserPhone(data.phone)
      }
      setDevice(`${mobileVendor} ${mobileModel} (${deviceType})`)
      const { userIp } = await (await fetch('/api/ip')).json()
      // const currentHour = new Date().getHours();
      // const brand = Number(currentHour > 12);
      setUserIp(userIp)
      if (props.link_code) {
        await getPrevData(props.data)
        await upsertDetails({ link_code: props.link_code, user_ip: userIp })
      }
      setInit(false)
    })()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('usr_data')) {
      setShowBackdrop(false)
    }
    if (init) {
      disableScroll()
      return
    }
    setTimeout(() => {
      const nextStep = getNextStep()
      setStep(nextStep)
      setTimeout(
        () => {
          setShowBackdrop(false)
          enableScroll()
        },
        nextStep === STEP.INIT ? 0 : 10
      )
    }, 0)

    //eslint-disable-next-line
  }, [init])

  useEffect(() => {
    if (!Object.keys(stepValid).length) {
      return
    }
    localStorage.setItem('step_valid', JSON.stringify(stepValid))
  }, [JSON.stringify(stepValid)])

  return (
    <Layout>
      <>
        {fileURL === PDFTYPE.TERMS_OF_ENGAGEMENT && <TermsModal />}
        {fileURL === PDFTYPE.PRIVACY_POLICY && <PrivacyModal />}
        {fileURL === PDFTYPE.R40 && <R40Modal />}
        {fileURL === PDFTYPE.AUTHORIZE_AGENT_648 && <AuthorizeAgentModal />}
      </>
      {showLoadingPage && <Loading />}
      <div>
        {step > STEP.INIT ? (
          <Claim />
        ) : (
          <>
            <HeroSection />
            <Faq questions={questions} />
          </>
        )}
      </div>
      <Toaster
        richColors
        position="bottom-right"
        visibleToasts={5}
        toastOptions={{
          classNames: {
            title: 'font-sans text-sm',
          },
        }}
      />
    </Layout>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  context.res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')
  let { c: link_code } = context.query

  let data = null as Record<string, any> | null

  if (link_code) {
    const supabase = createServerClient(context)
    const { data: result } = await supabase.rpc('fetch_customer', { p_unique_link: link_code })
    if (result?.signatureUrl) {
      link_code = undefined
    } else {
      data = result
    }
  }
  return {
    props: {
      link_code: link_code && data ? link_code : null,
      data,
    },
  }
}

// Workaround for issues with function size limit
export const config = {
  unstable_excludeFiles: ['public/**/*', 'node_modules/canvas/**/*'],
}
