import { HTMLProps } from 'react'

type Props = {
  /**
   * Label to be displayed in chip
   */
  label: string
  /**
   * Function to be called when clear icon is clicked
   */
  onClear: () => void
  /**
   * Color variant to be applied to chip
   */
  color?: 'blue' | 'red' | 'yellow' | 'green'
} & HTMLProps<HTMLSpanElement>

const Chip = ({ color = 'blue', onClear, ...props }: Props) => {
  const chipColorVariants = {
    red: `bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300`,
    blue: `bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300`,
    green: `bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300`,
    yellow: `bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300`,
  }
  const clearBtnColorVariants = {
    red: `bg-red-300 hover:bg-red-200 hover:text-red-900 dark:hover:bg-red-600 dark:hover:text-red-300`,
    blue: `bg-blue-300 hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-600 dark:hover:text-blue-300`,
    green: `bg-green-300 hover:bg-green-200 hover:text-green-900 dark:hover:bg-green-600 dark:hover:text-green-300`,
    yellow: `bg-yellow-300 hover:bg-yellow-200 hover:text-yellow-900 dark:hover:bg-yellow-600 dark:hover:text-yellow-300`,
  }
  return (
    <span
      className={`flex w-fit items-center justify-between gap-2 rounded-full px-3  py-1.5 text-sm font-bold ${chipColorVariants[color]}`}
    >
      {props.label}
      <span
        role="button"
        className={`inline-flex  h-5 w-5 items-center justify-center rounded-full hover:bg-[#FFBDAD] hover:text-[#DE350B] dark:bg-gray-800 ${clearBtnColorVariants[color]}`}
        onClick={onClear}
        {...props}
      >
        <svg data-testid="delete-icon" height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
          <path
            fill={'currentColor'}
            d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
          ></path>
        </svg>
      </span>
    </span>
  )
}

export default Chip
