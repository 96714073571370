import { ChangeEvent, useEffect, useState } from 'react'
import { useSystemValues } from '@/contexts/ValueContext'
import InputHelper from '@qtc-repo/ui/components/Form/InputHelper'
import TaxDeductionModal from '../Modals/TaxDeductionModal'
import { REFUNDS, SectionIds, STEP } from '@qtc-repo/common/constants'
import { toast } from 'sonner'
import { getElementTop, scrollToTop, validateRefund } from '@qtc-repo/common/utils'
import MuiSelect from '@qtc-repo/ui/components/Select/MuiSelect'
import Chip from '@qtc-repo/ui/components/Chip/Chip'
import AddMore from '@qtc-repo/ui/components/AddMore/AddMore'
import LinkText from '@qtc-repo/ui/components/LinkText/LinkText'
import dynamic from 'next/dynamic'
import { nanoid } from 'nanoid'

const CustomCurrencyField = dynamic(() => import('@qtc-repo/ui/components/CurrencyInput/CustomCurrencyField'), {})
const Checkbox = dynamic(() => import('@qtc-repo/ui/components/Checkbox/Checkbox'))

export enum TAX_YEARS {
  APR062018_APR052019 = '6 April 2018 and 5 April 2019',
  APR062019_APR052020 = '6 April 2019 and 5 April 2020',
  APR062020_APR052021 = '6 April 2020 and 5 April 2021',
  APR062021_APR052022 = '6 April 2021 and 5 April 2022',
}

export const calculateTaxDeduction = (value: string) => {
  value = value.replace(/,/g, '')
  const claim = Number(value) * 0.0952
  return claim.toFixed(2)
}

const Refunds = () => {
  const { setStepValid, lendersData, refunds, setRefunds, step, setStep, payoutDone, setPayoutDone, firstEvents } =
    useSystemValues()
  const [showModal, setShowModal] = useState(false)

  const can_proceed =
    lendersData.selectedLenders.length > 0 &&
    lendersData.selectedLenders.every(lender => {
      return refunds[lender].every(refund => validateRefund(refund)) && payoutDone[lender]
    })
  useEffect(() => {
    setStepValid(prevState => ({
      ...prevState,
      [STEP.REFUNDS]: can_proceed,
      [STEP.SUMMARY]: can_proceed,
    }))
    //eslint-disable-next-line
  }, [can_proceed])

  useEffect(() => {
    if (step === STEP.REFUNDS && lendersData.selectedLenders.length === 0) {
      setStep(STEP.LENDERS)
    }
    //eslint-disable-next-line
  }, [lendersData, step])

  useEffect(() => {
    for (const lender of lendersData.selectedLenders) {
      if (payoutDone[lender]) {
        toast.dismiss(`${lender}-payout-done-toast`)
      }
      for (let index = 0; index < refunds[lender].length; index++) {
        const refund = refunds[lender][index]
        if (+refund?.tax_deduction.replace(/,/g, '') > 0) {
          toast.dismiss(`${lender}-${index}-refund-tax-toast`)
        }
        if (+refund?.amount.replace(/,/g, '') > 0) {
          toast.dismiss(`${lender}-${index}-refund-amount-toast`)
        }
        if (refund?.year) {
          toast.dismiss(`${lender}-${index}-refund-year-toast`)
        }
      }
    }
    localStorage.setItem('payout_done', JSON.stringify(payoutDone))
    //eslint-disable-next-line
  }, [refunds, payoutDone])
  const scrollToNextSection = (lender_index: number) => {
    let scroll_point_found = false
    for (const lender of lendersData.selectedLenders.slice(lender_index + 1)) {
      for (let j = 0; j < refunds[lender].length; j++) {
        if (!validateRefund(refunds[lender][j])) {
          scrollToTop(getElementTop(lender + '-' + j + '-' + SectionIds.REFUNDS))
          scroll_point_found = true
          break
        }
        if (j === refunds[lender].length - 1 && !payoutDone[lender]) {
          scrollToTop(getElementTop(lender + '-' + j + '-' + SectionIds.REFUNDS))
          scroll_point_found = true
          break
        }
      }
    }

    if (!scroll_point_found) {
      scrollToTop(getElementTop('btnNext-' + step))
    }
  }

  return (
    <div className="grid gap-5">
      {lendersData.selectedLenders.map((lender, index) => (
        <div key={lender} className={'flex flex-col gap-10'}>
          {refunds[lender]?.map((refund, index) => (
            <RefundSection refund={refund} lender={lender} index={index} setShowModal={setShowModal} key={refund.id} />
          ))}
          <div className={'flex flex-col gap-5'}>
            <div className={'w-fit'}>
              <AddMore
                disabled={refunds[lender].length === 5}
                label={<>Add another</>}
                onClick={() => {
                  if (refunds[lender].length === 5) {
                    return
                  }
                  refunds[lender].push({
                    year: '',
                    amount: '',
                    tax_deduction: '',
                    id: `${lender}-${nanoid(12)}`,
                    firstEvent: {
                      year: true,
                      amount: true,
                      tax_deduction: true,
                    },
                  })
                  if (refunds[lender].length === 5) {
                    setPayoutDone({ ...payoutDone, [lender]: true })
                  }
                  setRefunds({ ...refunds })
                }}
              />
            </div>
            <Checkbox
              id={`payout-done-${lender}`}
              error={!firstEvents.refunds && !payoutDone[lender]}
              checked={payoutDone[lender] || refunds[lender].length === 5}
              onChange={checked => {
                setPayoutDone({
                  ...payoutDone,
                  [lender]: checked && refunds[lender].length < 5,
                })
                if (checked) {
                  setTimeout(() => scrollToNextSection(index), 0)
                }
              }}
              label={
                <span className="text-base">
                  I did not receive another refund from <strong>{lender}</strong>
                </span>
              }
            />
          </div>

          {index < lendersData.selectedLenders.length - 1 && (
            <div className={'my-5 border-b-2 border-dashed border-gray-200 dark:border-gray-700'} />
          )}
        </div>
      ))}
      <TaxDeductionModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  )
}
const RefundSection = ({
  refund,
  lender,
  index,
  setShowModal,
}: {
  refund: REFUNDS[string][number]
  lender: string
  index: number
  setShowModal: (v: boolean) => void
}) => {
  const { lendersData, setLendersData, refunds, setRefunds } = useSystemValues()
  const REFUND_YEARS = ['Before 2019', '2019', '2020', '2021', '2022']
  const removeLender = () => {
    refunds[lender] = refunds[lender].filter((_, idx) => idx !== index)
    toast.dismiss(`${lender}-${index}-refund-tax-toast`)
    toast.dismiss(`${lender}-${index}-refund-amount-toast`)
    toast.dismiss(`${lender}-${index}-refund-year-toast`)
    toast.dismiss(`${lender}-payout-done-toast`)

    if (refunds[lender].length === 0) {
      setLendersData({
        ...lendersData,
        selectedLenders: lendersData.selectedLenders.filter(l => l !== lender),
      })
    }

    setRefunds({ ...refunds })
  }

  const sortRefunds = (refunds: REFUNDS[string]) => {
    return refunds.sort((a, b) => {
      const order: Record<string, number> = {
        '': 10,
        'Before 2019': 0,
        '2019': 1,
        '2020': 2,
        '2021': 3,
        '2022': 4,
      }
      return order[a.year] - order[b.year]
    })
  }

  const handleChange = (field: 'year' | 'amount' | 'tax_deduction', lender: string, value: string) => {
    setRefunds(prev => ({
      ...prev,
      [lender]: sortRefunds(
        prev[lender].map((lendr, idx) =>
          idx === index
            ? {
                ...lendr,
                [field]: value,
                firstEvent: {
                  ...lendr.firstEvent,
                  [field]: false,
                },
              }
            : lendr
        )
      ),
    }))
  }

  const selectedYears = Object.keys(refunds)
    .map(key =>
      refunds[key].map((refund, index) => ({
        lender: key,
        year: refund.year,
        index,
      }))
    )
    .flat()
  return (
    <div key={lender} id={refund.id}>
      <MuiSelect
        id={`${lender}-${index}-refund-year`}
        name={`${lender}-${index}-refund-year`}
        value={refund?.year || ''}
        onChange={e => handleChange('year', lender, e.target.value)}
        error={!refund?.firstEvent?.year && !refund?.year}
        success={!!refund?.year}
        options={REFUND_YEARS.map(key => ({
          label: key,
          value: key,
          disabled: !!selectedYears.find(
            ({ year, lender: lendr, index: idx }) => year === key && lendr === lender && index !== idx
          ),
        }))}
        label={
          <div className={'flex items-end justify-between text-lg'}>
            <span className={'leading-tight'}>Select year</span>
            <Chip label={lender} onClear={removeLender} />
          </div>
        }
      />
      <div className="mt-5 grid w-full grid-cols-1 flex-col gap-5 sm:grid-cols-4 sm:flex-row">
        <div className="sm:col-span-2">
          <CustomCurrencyField
            name={`${lender}-${index}-amount`}
            id={`${lender}-${index}-amount`}
            value={refund?.amount + '' || ''}
            label="Total amount received"
            currencySymbol="￡"
            placeholder={' '}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange('amount', lender, e.target.value)
              if (Number(e.target.value.replace(',', '')) > 0) {
                handleChange('tax_deduction', lender, calculateTaxDeduction(e.target.value))
              } else {
                handleChange('tax_deduction', lender, '')
                setRefunds(prev => ({
                  ...prev,
                  [lender]: prev[lender].map((lendr, idx) =>
                    idx === index
                      ? {
                          ...lendr,
                          firstEvent: {
                            ...lendr.firstEvent,
                            tax_deduction: true,
                          },
                        }
                      : lendr
                  ),
                }))
              }
            }}
            success={!!refund?.amount && Number(refund.amount.replace(/,/g, '')) > 0}
            error={!(!!refund?.amount && Number(refund.amount.replace(/,/g, '')) > 0) && !refund?.firstEvent?.amount}
            helper={
              !refund?.firstEvent?.amount && !refund?.amount ? (
                <InputHelper text="Please enter the total PPI refund amount." error />
              ) : null
            }
          />
        </div>

        <div className="sm:col-span-2">
          <CustomCurrencyField
            name={`${lender}-${index}-tax_deduction`}
            id={`${lender}-${index}-tax`}
            value={refund?.tax_deduction + '' || ''}
            label="Tax deduction"
            placeholder={' '}
            currencySymbol="￡"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('tax_deduction', lender, e.target.value)}
            success={!!refund?.tax_deduction && Number(refund.tax_deduction.replace(/,/g, '')) > 0}
            error={
              !(!!refund?.tax_deduction && Number(refund.tax_deduction.replace(/,/g, '')) > 0) &&
              !refund?.firstEvent?.tax_deduction
            }
            helper={
              <>
                {!(refund?.firstEvent?.tax_deduction || refund?.tax_deduction) && (
                  <InputHelper error={!refund?.firstEvent?.tax_deduction && !refund?.tax_deduction}>
                    <span>
                      Please enter the tax deduction amount.{' '}
                      <LinkText text={'Tell me more'} onClick={() => setShowModal(true)} />
                    </span>
                  </InputHelper>
                )}
                <InputHelper noIcon={!refund?.firstEvent?.tax_deduction && !refund?.tax_deduction}>
                  {(refund?.firstEvent?.tax_deduction || refund?.tax_deduction) && (
                    <span className=" whitespace-pre-line ">
                      We have estimated the amount of tax deducted based on average values. If this amount is incorrect,
                      you can click to update and enter manually.{' '}
                      <LinkText text={'Tell me more'} onClick={() => setShowModal(true)} />
                    </span>
                  )}
                </InputHelper>
              </>
            }
          />
        </div>
      </div>
    </div>
  )
}
export default Refunds
