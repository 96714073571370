import { type ReactNode } from 'react'

const Main = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex min-h-[100%] w-full flex-grow flex-col items-center justify-center bg-gradient-to-b from-gray-50 to-transparent dark:from-gray-900 dark:to-gray-900">
      <main className="relative w-full overflow-x-hidden">{children}</main>
    </div>
  )
}

export default Main
