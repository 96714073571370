import React, { ReactNode } from 'react'
import { PlusCircle } from 'lucide-react'
import { Button } from '../Button'
type Props = {
  label: ReactNode
  onClick: VoidFunction
  disabled?: boolean
}
const AddMore = (props: Props) => {
  return (
    <Button
      disabled={props.disabled}
      variant="default"
      className="gap-1"
      onClick={event => {
        event.preventDefault()
        props.onClick()
      }}
    >
      <PlusCircle className="size-4" />
      {props.label}
    </Button>
  )
}

export default AddMore
